._1H7C6 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  font-family: Arial, Helvetica, sans-serif; }

._Adysl {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  font-family: Arial, Helvetica, sans-serif; }

#_2mcKC {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 0.7em; }

#_77wmf {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 0.9em; }

#_2OJv0 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 1em; }

#_24i7u {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  transition: all 0.3s ease, opacity 0.2s ease; }
  #_24i7u._3nIZK {
    visibility: hidden;
    opacity: 0;
    height: 0; }

._3G14Z {
  padding: 2px;
  font-size: 25px; }

._5yJF4 {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  margin: 10px;
  width: 40px;
  height: 40px;
  margin: 5px;
  font-size: 1em; }
  ._5yJF4:focus {
    outline: none;
    box-shadow: none; }
  ._5yJF4:active {
    transition: none;
    opacity: 0.5; }
  ._5yJF4:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }

._oUPm8 {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  margin: 10px;
  width: 50px;
  height: 50px;
  margin: 8px;
  font-size: 1.2em; }
  ._oUPm8:focus {
    outline: none;
    box-shadow: none; }
  ._oUPm8:active {
    transition: none;
    opacity: 0.5; }
  ._oUPm8:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }

._2hCkj {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  margin: 10px;
  width: 60px;
  height: 60px;
  margin: 10px;
  font-size: 1.5em; }
  ._2hCkj:focus {
    outline: none;
    box-shadow: none; }
  ._2hCkj:active {
    transition: none;
    opacity: 0.5; }
  ._2hCkj:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }

._N-jqm {
  font-size: 0.5em;
  color: #495057; }

._19SxG {
  display: flex;
  flex-direction: row; }

#_NY-3t {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  background-color: #ced4da;
  margin: 10px;
  width: 40px;
  height: 40px;
  margin: 5px; }
  #_NY-3t:focus {
    outline: none;
    box-shadow: none; }
  #_NY-3t:active {
    transition: none;
    opacity: 0.5; }
  #_NY-3t:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }
  #_NY-3t._3ZwLv {
    background-color: #495057; }

#_QlUP6 {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  background-color: #ced4da;
  margin: 10px;
  width: 50px;
  height: 50px;
  margin: 8px; }
  #_QlUP6:focus {
    outline: none;
    box-shadow: none; }
  #_QlUP6:active {
    transition: none;
    opacity: 0.5; }
  #_QlUP6:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }
  #_QlUP6._3ZwLv {
    background-color: #495057; }

#_CsQpv {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  background-color: #ced4da;
  margin: 10px;
  width: 60px;
  height: 60px;
  margin: 10px; }
  #_CsQpv:focus {
    outline: none;
    box-shadow: none; }
  #_CsQpv:active {
    transition: none;
    opacity: 0.5; }
  #_CsQpv:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }
  #_CsQpv._3ZwLv {
    background-color: #495057; }

._8Ja3- {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  background-color: #ced4da;
  margin: 10px;
  background-color: #e5383b;
  width: 40px;
  height: 40px;
  margin: 5px; }
  ._8Ja3-:focus {
    outline: none;
    box-shadow: none; }
  ._8Ja3-:active {
    transition: none;
    opacity: 0.5; }
  ._8Ja3-:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }

._3K9EK {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  background-color: #ced4da;
  margin: 10px;
  background-color: #e5383b;
  width: 50px;
  height: 50px;
  margin: 8px; }
  ._3K9EK:focus {
    outline: none;
    box-shadow: none; }
  ._3K9EK:active {
    transition: none;
    opacity: 0.5; }
  ._3K9EK:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }

._1rxit {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  background-color: #ced4da;
  margin: 10px;
  background-color: #e5383b;
  width: 60px;
  height: 60px;
  margin: 10px; }
  ._1rxit:focus {
    outline: none;
    box-shadow: none; }
  ._1rxit:active {
    transition: none;
    opacity: 0.5; }
  ._1rxit:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }

._2LJYU {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  background-color: #ced4da;
  margin: 10px;
  background-color: #80ed99;
  width: 40px;
  height: 40px;
  margin: 5px; }
  ._2LJYU:focus {
    outline: none;
    box-shadow: none; }
  ._2LJYU:active {
    transition: none;
    opacity: 0.5; }
  ._2LJYU:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }

._J4Xe3 {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  background-color: #ced4da;
  margin: 10px;
  background-color: #80ed99;
  width: 50px;
  height: 50px;
  margin: 8px; }
  ._J4Xe3:focus {
    outline: none;
    box-shadow: none; }
  ._J4Xe3:active {
    transition: none;
    opacity: 0.5; }
  ._J4Xe3:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }

._z8JxO {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  background-color: #ced4da;
  margin: 10px;
  background-color: #80ed99;
  width: 60px;
  height: 60px;
  margin: 10px; }
  ._z8JxO:focus {
    outline: none;
    box-shadow: none; }
  ._z8JxO:active {
    transition: none;
    opacity: 0.5; }
  ._z8JxO:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }

._2kDeL {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center; }

#_1yjIy {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease; }
  #_1yjIy._3nIZK {
    visibility: hidden;
    opacity: 0;
    height: 0; }

#_2tho8 {
  border: none;
  padding: none;
  font-size: 1em;
  border: 2px solid #495057;
  border-radius: 100px;
  width: 80%;
  padding: 5px 10px 5px 10px; }
  #_2tho8:focus {
    outline: none; }

._hNLnI {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  margin: 10px;
  width: 40px;
  height: 40px;
  margin: 5px; }
  ._hNLnI:focus {
    outline: none;
    box-shadow: none; }
  ._hNLnI:active {
    transition: none;
    opacity: 0.5; }
  ._hNLnI:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }

._3xVYU {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  margin: 10px;
  width: 50px;
  height: 50px;
  margin: 8px; }
  ._3xVYU:focus {
    outline: none;
    box-shadow: none; }
  ._3xVYU:active {
    transition: none;
    opacity: 0.5; }
  ._3xVYU:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }

._YyaP5 {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  margin: 10px;
  width: 60px;
  height: 60px;
  margin: 10px; }
  ._YyaP5:focus {
    outline: none;
    box-shadow: none; }
  ._YyaP5:active {
    transition: none;
    opacity: 0.5; }
  ._YyaP5:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }

._Adysl {
  width: 100%;
  margin-top: 10px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; }

#_3TfJl {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  background: none;
  height: 24px;
  width: 24px;
  padding: 2px; }
  #_3TfJl:focus {
    outline: none;
    box-shadow: none; }
  #_3TfJl:active {
    transition: none;
    opacity: 0.5; }
  #_3TfJl:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }
  #_3TfJl._3ZwLv {
    background-color: #ADB5BD; }

#_6JtnT {
  flex-direction: column;
  transition: all 0.2s ease;
  width: 100%;
  justify-content: flex-start; }
  #_6JtnT._3nIZK {
    visibility: hidden;
    opacity: 0;
    height: 0; }

._2NuIJ {
  display: flex;
  flex-direction: row;
  margin: 5px 0 5px 0; }

._1K5Gw {
  padding: 0 5px 0 5px; }

._351uz {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  font-family: Arial, Helvetica, sans-serif; }

#_35MuX {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 0.7em; }

#_2TYqW {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 0.9em; }

#_3nIx5 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 1em; }

#_OZlk_ {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  transition: all 0.3s ease, opacity 0.2s ease; }
  #_OZlk_._hCG-D {
    visibility: hidden;
    opacity: 0;
    height: 0; }

._3-0Jr {
  padding: 2px;
  font-size: 25px; }

._1G6xM {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  margin: 10px;
  width: 40px;
  height: 40px;
  margin: 5px;
  font-size: 1em; }
  ._1G6xM:focus {
    outline: none;
    box-shadow: none; }
  ._1G6xM:active {
    transition: none;
    opacity: 0.5; }
  ._1G6xM:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }

._3VWlP {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  margin: 10px;
  width: 50px;
  height: 50px;
  margin: 8px;
  font-size: 1.2em; }
  ._3VWlP:focus {
    outline: none;
    box-shadow: none; }
  ._3VWlP:active {
    transition: none;
    opacity: 0.5; }
  ._3VWlP:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }

._J28b4 {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  margin: 10px;
  width: 60px;
  height: 60px;
  margin: 10px;
  font-size: 1.5em; }
  ._J28b4:focus {
    outline: none;
    box-shadow: none; }
  ._J28b4:active {
    transition: none;
    opacity: 0.5; }
  ._J28b4:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }

._25ZAm {
  font-size: 0.5em;
  color: #495057; }

._3q0-9 {
  display: flex;
  flex-direction: row; }

#_3cxMH {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  background-color: #ced4da;
  margin: 10px;
  width: 40px;
  height: 40px;
  margin: 5px; }
  #_3cxMH:focus {
    outline: none;
    box-shadow: none; }
  #_3cxMH:active {
    transition: none;
    opacity: 0.5; }
  #_3cxMH:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }
  #_3cxMH._3LWDa {
    background-color: #495057; }

#_1bC52 {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  background-color: #ced4da;
  margin: 10px;
  width: 50px;
  height: 50px;
  margin: 8px; }
  #_1bC52:focus {
    outline: none;
    box-shadow: none; }
  #_1bC52:active {
    transition: none;
    opacity: 0.5; }
  #_1bC52:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }
  #_1bC52._3LWDa {
    background-color: #495057; }

#_Y7b9y {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  background-color: #ced4da;
  margin: 10px;
  width: 60px;
  height: 60px;
  margin: 10px; }
  #_Y7b9y:focus {
    outline: none;
    box-shadow: none; }
  #_Y7b9y:active {
    transition: none;
    opacity: 0.5; }
  #_Y7b9y:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }
  #_Y7b9y._3LWDa {
    background-color: #495057; }

._2sQop {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  background-color: #ced4da;
  margin: 10px;
  background-color: #e5383b;
  width: 40px;
  height: 40px;
  margin: 5px; }
  ._2sQop:focus {
    outline: none;
    box-shadow: none; }
  ._2sQop:active {
    transition: none;
    opacity: 0.5; }
  ._2sQop:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }

._1oRJk {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  background-color: #ced4da;
  margin: 10px;
  background-color: #e5383b;
  width: 50px;
  height: 50px;
  margin: 8px; }
  ._1oRJk:focus {
    outline: none;
    box-shadow: none; }
  ._1oRJk:active {
    transition: none;
    opacity: 0.5; }
  ._1oRJk:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }

._3csc6 {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  background-color: #ced4da;
  margin: 10px;
  background-color: #e5383b;
  width: 60px;
  height: 60px;
  margin: 10px; }
  ._3csc6:focus {
    outline: none;
    box-shadow: none; }
  ._3csc6:active {
    transition: none;
    opacity: 0.5; }
  ._3csc6:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }

._5349J {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  background-color: #ced4da;
  margin: 10px;
  background-color: #80ed99;
  width: 40px;
  height: 40px;
  margin: 5px; }
  ._5349J:focus {
    outline: none;
    box-shadow: none; }
  ._5349J:active {
    transition: none;
    opacity: 0.5; }
  ._5349J:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }

._2qzLL {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  background-color: #ced4da;
  margin: 10px;
  background-color: #80ed99;
  width: 50px;
  height: 50px;
  margin: 8px; }
  ._2qzLL:focus {
    outline: none;
    box-shadow: none; }
  ._2qzLL:active {
    transition: none;
    opacity: 0.5; }
  ._2qzLL:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }

._1awK0 {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  background-color: #ced4da;
  margin: 10px;
  background-color: #80ed99;
  width: 60px;
  height: 60px;
  margin: 10px; }
  ._1awK0:focus {
    outline: none;
    box-shadow: none; }
  ._1awK0:active {
    transition: none;
    opacity: 0.5; }
  ._1awK0:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }

._1S5ik {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center; }

#_B_RC3 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease; }
  #_B_RC3._hCG-D {
    visibility: hidden;
    opacity: 0;
    height: 0; }

#_2INh- {
  border: none;
  padding: none;
  font-size: 1em;
  border: 2px solid #495057;
  border-radius: 100px;
  width: 80%;
  padding: 5px 10px 5px 10px; }
  #_2INh-:focus {
    outline: none; }

._1WxUQ {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  margin: 10px;
  width: 40px;
  height: 40px;
  margin: 5px; }
  ._1WxUQ:focus {
    outline: none;
    box-shadow: none; }
  ._1WxUQ:active {
    transition: none;
    opacity: 0.5; }
  ._1WxUQ:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }

._2rYd7 {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  margin: 10px;
  width: 50px;
  height: 50px;
  margin: 8px; }
  ._2rYd7:focus {
    outline: none;
    box-shadow: none; }
  ._2rYd7:active {
    transition: none;
    opacity: 0.5; }
  ._2rYd7:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }

._30xds {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  margin: 10px;
  width: 60px;
  height: 60px;
  margin: 10px; }
  ._30xds:focus {
    outline: none;
    box-shadow: none; }
  ._30xds:active {
    transition: none;
    opacity: 0.5; }
  ._30xds:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }

._351uz {
  width: 100%;
  margin-top: 10px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; }

#_1zv_c {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  background: none;
  height: 24px;
  width: 24px;
  padding: 2px; }
  #_1zv_c:focus {
    outline: none;
    box-shadow: none; }
  #_1zv_c:active {
    transition: none;
    opacity: 0.5; }
  #_1zv_c:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }
  #_1zv_c._3LWDa {
    background-color: #ADB5BD; }

#_1r_CB {
  flex-direction: column;
  transition: all 0.2s ease;
  width: 100%;
  justify-content: flex-start; }
  #_1r_CB._hCG-D {
    visibility: hidden;
    opacity: 0;
    height: 0; }

._K0aBl {
  display: flex;
  flex-direction: row;
  margin: 5px 0 5px 0; }

._2YbUI {
  padding: 0 5px 0 5px; }

._3xSlp {
  padding: 2px;
  font-size: 15px; }

._26mN7 {
  padding: 2px;
  font-size: 20px; }

._3pYEI {
  padding: 2px;
  font-size: 25px; }

._33s4p {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  font-family: Arial, Helvetica, sans-serif; }

#_Fzw8L {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 0.7em; }

#_2mM_j {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 0.9em; }

#_Ad_CD {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 1em; }

#_-iUpI {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  transition: all 0.3s ease, opacity 0.2s ease; }
  #_-iUpI._1Yn0M {
    visibility: hidden;
    opacity: 0;
    height: 0; }

._3n9O3 {
  padding: 2px;
  font-size: 25px; }

._3aOAk {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  margin: 10px;
  width: 40px;
  height: 40px;
  margin: 5px;
  font-size: 1em; }
  ._3aOAk:focus {
    outline: none;
    box-shadow: none; }
  ._3aOAk:active {
    transition: none;
    opacity: 0.5; }
  ._3aOAk:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }

._39TL3 {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  margin: 10px;
  width: 50px;
  height: 50px;
  margin: 8px;
  font-size: 1.2em; }
  ._39TL3:focus {
    outline: none;
    box-shadow: none; }
  ._39TL3:active {
    transition: none;
    opacity: 0.5; }
  ._39TL3:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }

._3LPOh {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  margin: 10px;
  width: 60px;
  height: 60px;
  margin: 10px;
  font-size: 1.5em; }
  ._3LPOh:focus {
    outline: none;
    box-shadow: none; }
  ._3LPOh:active {
    transition: none;
    opacity: 0.5; }
  ._3LPOh:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }

._30C7x {
  font-size: 0.5em;
  color: #495057; }

._ftZ8R {
  display: flex;
  flex-direction: row; }

#_2M9aX {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  background-color: #ced4da;
  margin: 10px;
  width: 40px;
  height: 40px;
  margin: 5px; }
  #_2M9aX:focus {
    outline: none;
    box-shadow: none; }
  #_2M9aX:active {
    transition: none;
    opacity: 0.5; }
  #_2M9aX:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }
  #_2M9aX._11LDZ {
    background-color: #495057; }

#_2mr3P {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  background-color: #ced4da;
  margin: 10px;
  width: 50px;
  height: 50px;
  margin: 8px; }
  #_2mr3P:focus {
    outline: none;
    box-shadow: none; }
  #_2mr3P:active {
    transition: none;
    opacity: 0.5; }
  #_2mr3P:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }
  #_2mr3P._11LDZ {
    background-color: #495057; }

#_7z8Lb {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  background-color: #ced4da;
  margin: 10px;
  width: 60px;
  height: 60px;
  margin: 10px; }
  #_7z8Lb:focus {
    outline: none;
    box-shadow: none; }
  #_7z8Lb:active {
    transition: none;
    opacity: 0.5; }
  #_7z8Lb:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }
  #_7z8Lb._11LDZ {
    background-color: #495057; }

._3OpQZ {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  background-color: #ced4da;
  margin: 10px;
  background-color: #e5383b;
  width: 40px;
  height: 40px;
  margin: 5px; }
  ._3OpQZ:focus {
    outline: none;
    box-shadow: none; }
  ._3OpQZ:active {
    transition: none;
    opacity: 0.5; }
  ._3OpQZ:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }

._tWpoE {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  background-color: #ced4da;
  margin: 10px;
  background-color: #e5383b;
  width: 50px;
  height: 50px;
  margin: 8px; }
  ._tWpoE:focus {
    outline: none;
    box-shadow: none; }
  ._tWpoE:active {
    transition: none;
    opacity: 0.5; }
  ._tWpoE:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }

._1MsjV {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  background-color: #ced4da;
  margin: 10px;
  background-color: #e5383b;
  width: 60px;
  height: 60px;
  margin: 10px; }
  ._1MsjV:focus {
    outline: none;
    box-shadow: none; }
  ._1MsjV:active {
    transition: none;
    opacity: 0.5; }
  ._1MsjV:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }

._3srXs {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  background-color: #ced4da;
  margin: 10px;
  background-color: #80ed99;
  width: 40px;
  height: 40px;
  margin: 5px; }
  ._3srXs:focus {
    outline: none;
    box-shadow: none; }
  ._3srXs:active {
    transition: none;
    opacity: 0.5; }
  ._3srXs:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }

._2nTvK {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  background-color: #ced4da;
  margin: 10px;
  background-color: #80ed99;
  width: 50px;
  height: 50px;
  margin: 8px; }
  ._2nTvK:focus {
    outline: none;
    box-shadow: none; }
  ._2nTvK:active {
    transition: none;
    opacity: 0.5; }
  ._2nTvK:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }

._zuLWK {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  background-color: #ced4da;
  margin: 10px;
  background-color: #80ed99;
  width: 60px;
  height: 60px;
  margin: 10px; }
  ._zuLWK:focus {
    outline: none;
    box-shadow: none; }
  ._zuLWK:active {
    transition: none;
    opacity: 0.5; }
  ._zuLWK:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }

._25gV2 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center; }

#_1yYD- {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease; }
  #_1yYD-._1Yn0M {
    visibility: hidden;
    opacity: 0;
    height: 0; }

#_ovMXl {
  border: none;
  padding: none;
  font-size: 1em;
  border: 2px solid #495057;
  border-radius: 100px;
  width: 80%;
  padding: 5px 10px 5px 10px; }
  #_ovMXl:focus {
    outline: none; }

._12d_H {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  margin: 10px;
  width: 40px;
  height: 40px;
  margin: 5px; }
  ._12d_H:focus {
    outline: none;
    box-shadow: none; }
  ._12d_H:active {
    transition: none;
    opacity: 0.5; }
  ._12d_H:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }

._jQyfb {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  margin: 10px;
  width: 50px;
  height: 50px;
  margin: 8px; }
  ._jQyfb:focus {
    outline: none;
    box-shadow: none; }
  ._jQyfb:active {
    transition: none;
    opacity: 0.5; }
  ._jQyfb:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }

._3kTg5 {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  margin: 10px;
  width: 60px;
  height: 60px;
  margin: 10px; }
  ._3kTg5:focus {
    outline: none;
    box-shadow: none; }
  ._3kTg5:active {
    transition: none;
    opacity: 0.5; }
  ._3kTg5:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }

._2iAE_ {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  font-family: Arial, Helvetica, sans-serif; }

._3GsXr {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  height: 40px;
  width: 40px;
  background: none; }
  ._3GsXr:focus {
    outline: none;
    box-shadow: none; }
  ._3GsXr:active {
    transition: none;
    opacity: 0.5; }
  ._3GsXr:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }

._tfL15 {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  height: 80px;
  width: 80px;
  background: none; }
  ._tfL15:focus {
    outline: none;
    box-shadow: none; }
  ._tfL15:active {
    transition: none;
    opacity: 0.5; }
  ._tfL15:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }

._32AFz {
  border: none;
  padding: none;
  font-size: 1em;
  width: 100%; }
  ._32AFz:focus {
    outline: none; }

._2qSFk {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 50%;
  padding: 0 0 0 60px; }

._1bX9L {
  display: flex;
  flex-direction: row;
  width: 100%; }

._1wsrX {
  flex: 1;
  border: 1px solid #ADB5BD;
  padding: 5px;
  max-width: 300px;
  min-width: 100px;
  background-color: white;
  border-radius: 10px;
  font-size: 0.9em; }

._3aieU {
  padding: 2px;
  border: none;
  background: white;
  cursor: pointer; }
